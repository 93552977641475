<template>
  <div>
    <b-form @submit.stop.prevent="onSubmit">
      <TextInput
        ref="firstName"
        label="First Name"
        name="first-name"
        v-model="firstName"
        :required="false"
        :disabled="formDisabled"
      />
      <TextInput
        ref="lastName"
        label="Last Name"
        name="last-name"
        v-model="lastName"
        :required="false"
        :disabled="formDisabled"
      />

      <EmailInput ref="email" label="Email" name="email" v-model="email" :disabled="formDisabled" />

      <TextInput
        ref="username"
        label="Username"
        name="username"
        v-model="username"
        :required="false"
        :disabled="formDisabled"
      />

      <UserRolesInput :roles="roles" :email="email" :disabled="formDisabled" />

      <b-row class="mt-9 mb-3">
        <b-col>
          <b-form-checkbox
            v-model="disabled"
            :state="validateState('input-disabled')"
            name="input-disabled"
            switch
            :disabled="formDisabled"
          >
            Disabled User
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="frozen"
            :state="validateState('input-frozen')"
            name="input-frozen"
            switch
            :disabled="formDisabled"
          >
            Frozen
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import TextInput from '@/components/inputs/TextInput';
import EmailInput from '@/components/inputs/EmailInput';
import UserRolesInput from '@/components/forms/UserRolesInput';

export default {
  props: {
    user: {
      type: Object
    }
  },
  components: {
    TextInput,
    EmailInput,
    UserRolesInput
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      username: null,
      email: null,
      roles: null,
      disabled: null,
      frozen: null,
      formDisabled: true
    };
  },
  created() {
    this.resetForm();
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validate() {
      const lastName = this.$refs.lastName.isValid();
      const firstName = this.$refs.firstName.isValid();
      const email = this.$refs.email.isValid();

      return firstName && lastName && email;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async resetForm() {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.username = this.user.username;
      this.roles = this.user.roles;
      this.frozen = this.user.frozen;
      this.disabled = this.user.disabled;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    }
  }
};
</script>
