<template>
  <div class="flex flex-wrap items-start">
    <multiselect
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      :searchable="true"
      @search-change="searchChanged"
      :loading="loading"
      :allow-empty="true"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      placeholder="Select organizations you wish to add"
      name="organization-select"
      class="flex-1 lg:max-w-1/2 mr-4"
    >
      <template #noResult> No organizations found. </template>
    </multiselect>

    <Button variant="green" :loading="loading" @onClick="addOrganizations">Add</Button>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import OrganizationService from '@/lib/organization-service-v2';

import Button from '@/components/ui/LoadingButton';
import UserServiceV2 from '@/lib/user-service-v2';

export default {
  components: { Multiselect, Button },
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      error: null,
      loading: false,
      options: [],
      selected: [],
      initialized: false
    };
  },
  async mounted() {
    await this.loadOrganizations();
  },
  methods: {
    async loadOrganizations(query) {
      this.loading = true;

      const params = {
        sortBy: 'name',
        sortDir: 'asc',
        pageSize: 100
      };

      if (query) {
        params.search = query;
      }

      try {
        const response = await OrganizationService.listOrganizations(params);

        const options = [];

        for (const organization of response.data) {
          options.push(organization);
        }

        this.options = options;
      } catch (error) {
        this.error = `Failed to load organizations ${error.message}`;
      }

      this.loading = false;
    },
    async searchChanged(query) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => this.loadOrganizations(query), 300);
    },
    async addOrganizations() {
      if (this.selected.length) {
        this.loading = true;

        // collect IDs of orgs and strip out the primary org if the user tried to add it as a secondary
        const addedOrganizationIds = this.selected
          .map((org) => org.id)
          .filter((org) => org !== this.user.organizationId);

        const params = {
          organizationIds: [...this.user.secondaryOrganizations, ...addedOrganizationIds]
        };

        try {
          await UserServiceV2.updateUser(this.user.id, params);
          this.selected = [];
          this.$emit('userUpdated');
        } catch (error) {
          this.$emit('error', `Failed to add organization, ${this.parseError(error).message}`);
        } finally {
          await this.loadOrganizations();
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect {
  max-width: 100% !important;
}

.multiselect__tag {
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  background-color: #439547 !important;
}

.multiselect__option--highlight {
  background-color: #439547 !important;
}
</style>
